import {Injectable} from '@angular/core';
import {UserFilterDTO} from "../../types/UserFilterDTO";
import {UserDTO} from "../../types/UserDTO";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {ToastrService} from "ngx-toastr";
import {PageEvent} from "@angular/material/paginator";
import {CourseDTO} from "../../types/CourseDTO";
import {CourseFilterDTO} from "../../types/CourseFilterDTO";
import {CourseEnrollmentFilterDTO} from "../../types/CourseEnrollmentFilterDTO";
import {CourseEnrollmentDTO} from "../../types/CourseEnrollmentDTO";

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  // private urlBase: string = "http://localhost:8050/admin/course/";
  private urlBase: string = "http://sparkfamily.org:8050/admin/course/";

  private addCourseUrl: string = this.urlBase + "add";
  private editCourseUrl: string = this.urlBase + "edit";
  private getCourseUrl: string = this.urlBase + "get";
  private inactivateCourseUrl: string = this.urlBase + "inactivate";
  private activateCourseUrl: string = this.urlBase + "activate";
  private removeCourseUrl: string = this.urlBase + "remove";
  private filterCourseUrl: string = this.urlBase + "findAll";
  private filterCourseEnrollmentsUrl: string = this.urlBase + "@id/enrollments";
  private findCourseEnrollmentsUrl: string = this.urlBase + "@id/enrollments/@enrollmentId";

  private updateCourseEnrollmentsDateUrl: string = this.urlBase + "@id/enrollments/@enrollmentId";
  private updateCourseCompleteDateUrl: string = this.urlBase + "@id/enrollments/@enrollmentId/complete-course";

  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  add(courseDTO: CourseDTO) {
    return this.http.post<any>(this.addCourseUrl, courseDTO).pipe(catchError(this.handleError('ManageCourseService: AddCourse', [])));
  }

  edit(id: string, courseDTO: CourseDTO) {
    return this.http.post<any>(this.editCourseUrl + "/" + id, courseDTO).pipe(catchError(this.handleError('ManageCourseService: EditCourse', [])));
  }

  get(id: string) {
    return this.http.get<any>(this.getCourseUrl + '/' + id).pipe(catchError(this.handleError('ManageCourseService: GetCourse', [])));
  }

  inactivate(id: number) {
    return this.http.put<any>(this.inactivateCourseUrl + '/' + id, null).pipe(catchError(this.handleError('ManageCourseService: InActivateCurse', [])));
  }

  activate(id: number) {
    return this.http.put<any>(this.activateCourseUrl + '/' + id, null).pipe(catchError(this.handleError('ManageCourseService: ActivateCourse', [])));
  }

  remove(id: number) {
    return this.http.delete<any>(this.removeCourseUrl + '/' + id).pipe(catchError(this.handleError('ManageCourseService: RemoveCourse', [])));
  }

  filter(dto: CourseFilterDTO): Observable<any> {
    return this.http.post<any>(this.filterCourseUrl, dto).pipe(catchError(this.handleError('ManageCourseService: FilterRecords', [])));
  }

  findAll(event?: PageEvent): Observable<any> {
    return this.http.post<any>(this.filterCourseUrl, event).pipe(catchError(this.handleError('ManageCourseService: FindAll', [])));
  }

  findEnrollments(id: string, dto: CourseEnrollmentFilterDTO): Observable<any> {
    return this.http.post<any>(this.filterCourseEnrollmentsUrl.replace("@id", id.toString()), dto).pipe(catchError(this.handleError('ManageCourseService: findEnrollments', [])));
  }

  getEnrollment(id: string, enrollmentId: string): Observable<any> {
    let url = this.findCourseEnrollmentsUrl;
    url = url.replace('@id', id.toString());
    url = url.replace('@enrollmentId', enrollmentId.toString());
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageCourseService: findEnrollment', [])));
  }

  updateEnrollmentDate(id: string, enrollmentId: string, dto: CourseEnrollmentDTO): Observable<any> {
    let url = this.updateCourseEnrollmentsDateUrl;
    url = url.replace('@id', id.toString());
    url = url.replace('@enrollmentId', enrollmentId.toString());
    return this.http.post<any>(url, dto).pipe(catchError(this.handleError('ManageCourseService: updateEnrollmentDate', [])));
  }

  updateCourseCompleteDate(id: string, enrollmentId: string): Observable<any> {
    let url = this.updateCourseCompleteDateUrl;
    url = url.replace('@id', id.toString());
    url = url.replace('@enrollmentId', enrollmentId.toString());
    return this.http.post<any>(url, null).pipe(catchError(this.handleError('ManageCourseService: updateCourseCompleteDate', [])));
  }

  clear() {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("course service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      this.toastr.error(error.error, "Error")
      return of(result as T);
    };
  }
}
