import { Component, OnInit } from '@angular/core';
import {Curriculum} from "../../types/Curriculum";
import {ActivatedRoute, ActivatedRouteSnapshot} from "@angular/router";
import {Module} from "../../types/Module";
import {Breadcrumb} from "../../types/Breadcrumb";
import {AccountUserDTO} from "../../types/AccountUserDTO";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {DeleteUserDialogComponent} from "../delete-user-dialog/delete-user-dialog.component";
import {AcceptEulaDialogComponent} from "../accept-eula-dialog/accept-eula-dialog.component";
import {LicenseExpirationDateDTO} from "../../types/LicenseExpirationDateDTO";

@Component({
  selector: 'app-module',
  templateUrl: './module.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent implements OnInit {

  curriculums: Curriculum[];

  licenseExpirationDate: LicenseExpirationDateDTO;

  navEntries : any;

  modules: Module[];

  ttModules: Module[];

  curriculumId : number;

  currentCurriculum : Curriculum;

  hasTTPermission : boolean;

  accessingViaTrial : boolean;

  showEulaPopup : boolean;

  constructor(private dialog: MatDialog, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log("curriculum value");
    //this.route.data.subscribe(data => this.curriculums = data.valueOf("curriculums").curriculums);

    this.route.data.subscribe(data =>
    {
      console.log("MODULE COMPONENT")
      console.log(data);
      this.curriculums = data.curriculums;
      this.navEntries = data.navEntries;
      this.licenseExpirationDate = data.licenseExpirationDate;
      this.modules = data.modules;
      this.accessingViaTrial = data.accessingViaTrial.accessingViaTrial;
      this.showEulaPopup = data.showEulaPopup.showEula;

      console.log(this.showEulaPopup);

      if(this.showEulaPopup){
        this.openEulaDialog();
      }

      console.log("accessing via trial");
      console.log(data.accessingViaTrial);
      console.log(data.accessingViaTrial.accessingViaTrial);
      console.log(this.accessingViaTrial);
      console.log(this.curriculums);

      console.log(this.modules);

      this.currentCurriculum = data.currentCurriculum;
      console.log(this.currentCurriculum);

      this.hasTTPermission = data.hasTTPermission.hasPermission;
      console.log("has tt permission");
      console.log(this.hasTTPermission);

      if(data.ttModuleList){
        this.ttModules = data.ttModuleList;
      }

    });

    console.log("param id " + this.route.snapshot.params['id']);
    console.log(this.route);
    this.curriculumId = this.route.snapshot.params['id'];
  }


  openEulaDialog(){

    let openEulaDialogConfig = new MatDialogConfig();

    openEulaDialogConfig.autoFocus = true;
    openEulaDialogConfig.disableClose = true;
    openEulaDialogConfig.height= '120px';
    openEulaDialogConfig.width= '600px',

    openEulaDialogConfig.data = { };

    this.dialog.open(AcceptEulaDialogComponent, openEulaDialogConfig);

  }
}
