import { Component, OnInit } from '@angular/core';

export class UpdateUserDTO {
  email: string;
  school: string;
  firstName: string;
  lastName: string;
  password: string;
  id : number;
  accountId : number;

  constructor(){
  }

}
