import {Injectable} from '@angular/core';
import {UserFilterDTO} from "../../types/UserFilterDTO";
import {UserDTO} from "../../types/UserDTO";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {ToastrService} from "ngx-toastr";
import {PageEvent} from "@angular/material/paginator";
import {CourseDTO} from "../../types/CourseDTO";
import {CourseFilterDTO} from "../../types/CourseFilterDTO";
import {CourseEnrollmentFilterDTO} from "../../types/CourseEnrollmentFilterDTO";
import {CourseEnrollmentDTO} from "../../types/CourseEnrollmentDTO";
import {CreditLicenseDTO} from "../../types/CreditLicenseDTO";
import {CreditLicenseFilterDTO} from "../../types/CreditLicenseFilterDTO";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  // private urlBase: string = "http://localhost:8050/";
  private urlBase: string = "http://sparkfamily.org:8050/";

  private createLicenseeUrl: string = this.urlBase + "admin/se-license/create";
  private filterLicenseeUrl: string = this.urlBase + "admin/se-license/findAll";

  private assignLicenseeUrl: string = this.urlBase + "se-license/assign";
  private addEnrollmentUrl: string = this.urlBase + "se-license/addEnrollment";


  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  create(creditLicenseDTO: CreditLicenseDTO) {
    return this.http.post<any>(this.createLicenseeUrl, creditLicenseDTO).pipe(catchError(this.handleError('LicenseService: AddLicense', [])));
  }

  findAll(creditLicenseFilterDTO: CreditLicenseFilterDTO) {
    return this.http.post<any>(this.filterLicenseeUrl, creditLicenseFilterDTO).pipe(catchError(this.handleError('LicenseService: AssignLicense', [])));
  }

  assign(licenseKey: any) {
    return this.http.post<any>(this.assignLicenseeUrl, licenseKey).pipe(catchError(this.handleError('LicenseService: AssignLicense', [])));
  }

    remove(id: any) {
    return this.http.delete<any>(this.urlBase + id).pipe(catchError(this.handleError('LicenseService: remove', [])));
  }

  addEnrollment(formData: any) {
    return this.http.post<any>(this.addEnrollmentUrl,formData).pipe(catchError(this.handleError('LiveCourseService: addEnrollment', [])));
  }

  clear() {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("course service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      if (typeof error.error === 'object') {
        this.toastr.error(error.error.message, "Error")
      } else {
        this.toastr.error(error.error, "Error")
      }
      return of(result as T);
    };
  }
}
