import {Component, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {CourseDTO} from "../../../types/CourseDTO";
import {CourseContentService} from "../../../_services/adminCourseService/course-content-service";
import {CourseService} from "../../../_services/adminCourseService/course-service";
import {UserCourseService} from "../../../_services/userCourseService/user-course-service";
import {CourseTagDTO} from "../../../types/CourseTagDTO";
import {CourseCategoryDTO} from "../../../types/CourseCategoryDTO";

@Component({
  selector: 'app-e-course-summary',
  templateUrl: 'course-summary.html',
  styleUrls: ['course-summary.component.css']
})
export class eCourseSummaryComponent implements OnInit {

  courseUrl: string;
  header: string = "";
  courseLessonsSummary: any;
  courseDTO: CourseDTO;

  courseTags: CourseTagDTO[];
  courseCategories: CourseCategoryDTO[];

  totalLessonCount: number;
  completedLessonCount: number;

  constructor(private fb: FormBuilder,
              private courseContentService: CourseContentService,
              private userCourseService: UserCourseService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.courseUrl = this.route.snapshot.paramMap.get('courseUrl');
    this.userCourseService.findAllByCourseUrl(this.courseUrl).subscribe(data => {
      try {
        this.courseLessonsSummary = data.courseLessonSummaries;
        this.completedLessonCount = this.courseLessonsSummary.filter(e => e.courseStatus == 'FINISHED').length;
        this.totalLessonCount = this.courseLessonsSummary.length;

      } catch (e) {
        alert(e);
      }
    });
    if (this.courseUrl != null) {
      this.loadData(this.courseUrl);
    }
  }

  gotoCourseLesson(lessonId) {
    this.router.navigateByUrl("/sparkecademy-catalog/courses/" + this.courseUrl + "/lessons/" + lessonId);
  }


  loadData(courseUrl) {
    this.courseUrl = courseUrl;
    this.userCourseService.getByCourseUrl(this.courseUrl).subscribe(data => {
      let title = data.title;
      let subTitle = data.subTitle;
      let code = data.code;
      let description = data.description;
      let categoryId = (data.categoryId == null ? null : data.categoryId.toString());
      let cost = data.cost;
      let length = data.length;
      let isAlreadyEnrolled = data.isAlreadyEnrolled;
      let courseId = data.courseId;
      let categoryName = data.courseCategory.name;
      let categoryImg = data.courseCategory.img;
      let tagName = data.courseTag.name;
      let tagId = data.tagId == null ? null : data.tagId.toString();
      this.header = title;

      this.courseDTO = new CourseDTO(title, subTitle, description,
        length, cost, tagId, code,
        categoryId, null,null, null,
        null, categoryName,
        courseId, tagName,
        categoryImg, isAlreadyEnrolled);
    });
  }
}
