import { Component, OnInit } from '@angular/core';
import {RoleInfo} from "./RoleInfo";

export class LicenseDTO {

  licenseKey : string;
  expirationDate : string;

  roleInfoList : RoleInfo[];

  processedRoleInfoList : Array<Array<RoleInfo>> = new Array<Array<RoleInfo>>();

  licenseId : number;
  revoked : boolean;

  constructor(licenseKey : string, expirationDate : string, roleInfoList : RoleInfo[]){
    this.licenseKey = licenseKey;
    this.expirationDate = expirationDate;
    this.roleInfoList = roleInfoList;
  }

}
