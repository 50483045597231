import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LicenseDTO} from "../../../../types/LicenseDTO";
import {AccountUserDTO} from "../../../../types/AccountUserDTO";

@Component({
  selector: 'app-admin-account-license-show',
  templateUrl: './admin-account-license-show.html',
  styleUrls: ['./admin-account-license-show.component.css']
})
export class AdminAccountLicenseShowComponent implements OnInit {

  _licenseDTO : LicenseDTO;

  @Output() loadLicense = new EventEmitter<LicenseDTO>();

  constructor() { }

  ngOnInit() {
  }

  @Input()
  set licenseDTO(dto : LicenseDTO){
    this._licenseDTO = dto;
  }

  loadEditor(dto : LicenseDTO){
    this.loadLicense.emit(dto);
  }
}
