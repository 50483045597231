import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../../../types/User";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../errorMessageService/error-message.service";
import {map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class AdminAuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  //for local
  // private urlBase : string = "http://localhost:8050/admin/auth";
  // for qa
  private urlBase = 'http://sparkfamily.org:8050/admin/auth';
  //for production
  // private urlBase : string = "http://localhost:8050/admin/auth";
  private loginUrl : string  = this.urlBase + "/login";
  private resetTokenIsValidUrl : string = this.urlBase +"/resetTokenValid";
  private isAdminUserUrl : string = this.urlBase + "/isAdminUser";

  constructor(private http: HttpClient, private router: Router, private errorService : ErrorMessageService ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getCurrentUserName(): string {
    return this.getCurrentUserValue().firstName + " " + this.getCurrentUserValue().lastName;
  }

  public getCurrentUserValue() : User {
    console.log("getCurrentUserValue");
    console.log(localStorage.getItem('currentUser'));
    if(localStorage.getItem('currentUser')){
      return this.currentUserSubject.value;
    }
    //local storage item was lost in some event that wasn't trigger via logout
    //logout now
    this.logout(false);
  }
  public getCurrentUserValueNoLogout() : User {
    console.log("getCurrentUserValue");
    console.log(localStorage.getItem('currentUser'));
    if(localStorage.getItem('currentUser')){
      return this.currentUserSubject.value;
    }
  }

  login(email:string, password:string) {
    this.errorService.clear();
    return this.http.post<any>(this.loginUrl, {email, password}).pipe(map(user => {
      if(user && user.token){
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('useFamAdmin', 'true');
        this.currentUserSubject.next(user);
        this.router.navigateByUrl('admin-home');
      }

      if(user && user.error){
        this.errorService.clear();
        this.errorService.add(user.error);
      }

    }));
  }

  public isResetTokenValid(token : string) : Observable<any> {
    return this.http.post<any>(this.resetTokenIsValidUrl, token);
  }


  logout(redirect : boolean){
    console.log("logout: redirect: " + redirect);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("useFamAdmin");
    this.currentUserSubject.next(null);
    if(redirect) {
      this.router.navigateByUrl('/admin');
    }
  }

  public isLogginedIn(){
    return localStorage.getItem("currentUser") != null;
  }

  public useAdminUser(){
    return localStorage.getItem("useFamAdmin") != null;
  }

  public isAdminUser(){
    return this.http.get<any>(this.isAdminUserUrl);
  }

  public isloggedOut(){
    return !this.isLogginedIn();
  }



}
