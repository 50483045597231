import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../errorMessageService/error-message.service";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../../../types/User";
import {SearchUserDTO} from "../../../types/SearchUserDTO";
import {UpdateUserDTO} from "../../../types/UpdateUserDTO";
import {IDDTO} from "../../../types/IDDTO";
import {UpdateAccountRoleDTO} from "../../../types/UpdateAccountRoleDTO";
import {RegistrationDTO} from "../../../types/RegistrationDTO";
import {AdminRegistrationDTO} from "../../../types/AdminRegistrationDTO";
import {MergeIDDTO} from "../../../types/MergeIDDTO";

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  //for local
  // private urlBase: string = "http://localhost:8050/admin/user";
  //for qa
  private urlBase = 'http://sparkfamily.org:8050/admin/user';
  //for production
  // private urlBase : string = "http://localhost:8050/admin/user";
  private findByEmailUrl: string = this.urlBase + "/findUser";
  private findByAccountIdUrl: string = this.urlBase + "/findUserByAccountId";
  private findAccountByIdUrl: string = this.urlBase + "/findAccountById";
  private updateUserUrl: string = this.urlBase + "/updateUser";
  private updateAccountRoleUrl: string = this.urlBase + '/updateAccountUserRoles';
  private getAccountRoleStatsUrl: string = this.urlBase + '/getAccountRoleStats';
  private createNewUserUrl: string = this.urlBase + "/createNewUser";
  private deleteUserUrl: string = this.urlBase + "/deleteUser";
  private getAccountMergeInfoUrl: string = this.urlBase + "/account/mergeInfo";
  private getUserMergeInfoUrl: string = this.urlBase + "/user/mergeInfo";
  private completeAccountMergeUrl: string = this.urlBase + "/account/completeMerge";
  private completeUserMergeUrl: string = this.urlBase + "/user/completeMerge";
  private importUsersFromFileUrl: string = this.urlBase + "/importUsersFromFile";

  private dashboardSummaryUrl: string = this.urlBase + "/dashboardSummary";

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  findUsersByEmail(dto: SearchUserDTO) {

    return this.http.post<any>(this.findByEmailUrl, dto);

  }

  getUserMergeInfo(dto: MergeIDDTO) {
    return this.http.post<any>(this.getAccountMergeInfoUrl, dto);
  }

  completeAccountMerge(dto: MergeIDDTO) {
    return this.http.post<any>(this.completeAccountMergeUrl, dto);
  }

  completeUserMerge(dto: MergeIDDTO) {
    return this.http.post<any>(this.completeUserMergeUrl, dto);
  }

  getAccountMergeInfo(dto: MergeIDDTO) {
    return this.http.post<any>(this.getUserMergeInfoUrl, dto);
  }

  findUsersByAccountId(dto: IDDTO) {
    return this.http.post<any>(this.findByAccountIdUrl, dto);
  }

  findAccount(dto: IDDTO) {
    return this.http.post<any>(this.findAccountByIdUrl, dto);
  }

  updateUser(dto: UpdateUserDTO) {
    return this.http.post<any>(this.updateUserUrl, dto);
  }

  updateUserRoles(dtos: Array<UpdateAccountRoleDTO>) {
    return this.http.post<any>(this.updateAccountRoleUrl, dtos);
  }

  getAccountRoleInfo(id: number) {
    return this.http.get<any>(this.getAccountRoleStatsUrl + '/' + id);
  }

  createNewUser(dto: AdminRegistrationDTO) {
    return this.http.post<any>(this.createNewUserUrl, dto);
  }

  deleteUser(dto: IDDTO) {
    return this.http.post<any>(this.deleteUserUrl, dto);
  }

  importUsersFromFile(updateData) {
    return this.http.post<any>(this.importUsersFromFileUrl, updateData);
  }

  dashboardSummary() {
    return this.http.get<any>(this.dashboardSummaryUrl);
  }

}
