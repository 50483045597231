import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {ToastrService} from "ngx-toastr";
import {CourseLessonContentDTO} from "../../types/CourseLessonContentDTO";
import {CourseLessonQuizDTO} from "../../types/CourseLessonQuizDTO";

@Injectable({
  providedIn: 'root'
})
export class CourseContentService {

  // private urlBase: string = "http://localhost:8050/admin/course/@id/";
  private urlBase : string = "http://sparkfamily.org:8050/admin/course/@id/";
  // private urlBase: string = "http://localhost:8050/admin/course/@id/";

  private addCourseLessonsUrl: string = this.urlBase + "lessons/add";
  private editCourseLessonsUrl: string = this.urlBase + "lessons/@lessonId/edit";
  private findCourseLessonsSummaryUrl: string = this.urlBase + "lessons/findSummary";




  private findCourseLessonContentsUrl: string = this.urlBase + "lessonContents";

  private findCourseLessonQuizsUrl: string = this.urlBase + "lessonQuizs";
  private removeCourseLessonContentUrl: string = this.urlBase + "lesson/@lessonId/content";
  private removeCourseLessonQuizUrl: string = this.urlBase + "lesson/@lessonId/quiz";

  private removeCourseLessonsUrl: string = this.urlBase + "lesson";

  private addCourseLessonContentUrl: string = this.urlBase + "lesson/@lessonId/content";
  private addCourseLessonQuizUrl: string = this.urlBase + "lesson/@lessonId/quiz";

  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  findAll(id: string): Observable<any> {
    return this.http.post<any>(this.findCourseLessonsSummaryUrl.replace("@id", id), null).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  findLessonContents(courseId: string, lessonId: string): Observable<any> {
    return this.http.get<any>(this.findCourseLessonContentsUrl.replace("@id", courseId) + "/" + lessonId).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  findLessonQuiz(courseId: string, lessonId: string): Observable<any> {
    return this.http.get<any>(this.findCourseLessonQuizsUrl.replace("@id", courseId) + "/" + lessonId).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  addLesson(courseId: string, lessonTitle: string): Observable<any> {
    return this.http.post<any>(this.addCourseLessonsUrl.replace("@id", courseId), lessonTitle).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  editLessonTitle(courseId: string, lessonId: string, lessonTitle: string): Observable<any> {
    let url = this.editCourseLessonsUrl.replace("@id",courseId).replace("@lessonId",lessonId);
    return this.http.post<any>(url, lessonTitle).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  removeLesson(courseId: string, lessonId: string): Observable<any> {
    return this.http.delete<any>(this.removeCourseLessonsUrl.replace("@id", courseId) + "/" + lessonId).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  removeLessonContent(courseId: string, lessonId: string, contentId: string): Observable<any> {
    let url = this.removeCourseLessonContentUrl.replace("@id", courseId).replace("@lessonId", lessonId) + "/" + contentId;
    return this.http.delete<any>(url).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  removeLessonQuiz(courseId: string, lessonId: string, contentId: string): Observable<any> {
    let url = this.removeCourseLessonQuizUrl.replace("@id", courseId).replace("@lessonId", lessonId) + "/" + contentId;
    return this.http.delete<any>(url).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  addLessonContent(courseId: string, lessonId: string, inputData: FormData): Observable<any> {
    let url = this.addCourseLessonContentUrl.replace("@id", courseId).replace("@lessonId", lessonId);
    return this.http.post<any>(url, inputData).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  addLessonQuiz(courseId: string, lessonId: string, courseLessonQuizDTO: CourseLessonQuizDTO): Observable<any> {
    let url = this.addCourseLessonQuizUrl.replace("@id", courseId).replace("@lessonId", lessonId);
    return this.http.post<any>(url, courseLessonQuizDTO).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }


  clear() {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("course service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      this.toastr.error(error.error, "Error")
      return of(result as T);
    };
  }
}
