import {FileAudio} from "./FileAudio";

export class ModuleFile {
  success : boolean;
  error : string;

  id: string;
  navText: string;
  fileName: string;
  folder: boolean;
  hasSpanishFile: boolean;
  parentFolderId: string;
  curriculumId : string;
  moduleId : string;
  music: boolean;
  video: boolean;
  downloadable: boolean;
  displayOrder : number;
  trialAccessible : boolean;

  audioFiles : FileAudio[];

  constructor(id : string, navText : string, fileName : string, success : string, error : string, folder : boolean, hasSpanishFile : boolean, curriculumId : string, moduleId : string, music: boolean, video: boolean, downloadable: boolean, trialAccessible : boolean){
    this.id = id;
    this.error = error;
    this.navText = navText;
    this.fileName = fileName;
    this.success = success == 'true' ? true : false;
    this.folder = folder;
    this.hasSpanishFile = hasSpanishFile;
    this.curriculumId = curriculumId;
    this.moduleId = moduleId;
    this.music = music;
    this.video = video;
    this.downloadable = downloadable;
    this.trialAccessible = trialAccessible;
  }


}
