import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {ToastrService} from "ngx-toastr";
import {OnSiteCourseDTO} from "../../types/OnSiteCourseDTO";

@Injectable({
  providedIn: 'root'
})
export class OnSiteCourseService {

  // private urlBase: string = "http://localhost:8050/admin/liveCourse/";
  private urlBase: string = "http://sparkfamily.org:8050/admin/liveCourse/";

  private createLiveCourseUrl: string = this.urlBase + "add";
  private editLiveCourseUrl: string = this.urlBase + "edit";
  private viewLiveCourseUrl: string = this.urlBase + "view";
  private removeLiveCourseUrl: string = this.urlBase + "remove";
  private findAllUrl: string = this.urlBase + "findAll";
  private findEnrollmentsUrl: string = this.urlBase + "@id/findEnrollments";

  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  create(liveCourseDTO: OnSiteCourseDTO) {
    return this.http.post<any>(this.createLiveCourseUrl, liveCourseDTO).pipe(catchError(this.handleError('LiveCourseService: create', [])));
  }

  edit(id: number, liveCourseDTO: OnSiteCourseDTO) {
    let url = this.editLiveCourseUrl + "/" + id;
    return this.http.post<any>(url, liveCourseDTO).pipe(catchError(this.handleError('LiveCourseService: edit', [])));
  }

  get(id: number) {
    let url = this.viewLiveCourseUrl + "/" + id;
    return this.http.get<any>(url).pipe(catchError(this.handleError('LiveCourseService: remove', [])));
  }

  remove(id: number) {
    let url = this.removeLiveCourseUrl + "/" + id;
    return this.http.delete<any>(url).pipe(catchError(this.handleError('LiveCourseService: remove', [])));
  }

  findAllByCourseId(courseId: string) {
    return this.http.get<any>(this.findAllUrl + "?courseId=" + courseId).pipe(catchError(this.handleError('LiveCourseService: remove', [])));
  }


  findEnrollments(id: number) {
    let url = this.findEnrollmentsUrl.replace("@id", id.toString());
    return this.http.get<any>(url).pipe(catchError(this.handleError('LiveCourseService: remove', [])));
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("course service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      if (typeof error.error === 'object') {
        this.toastr.error(error.error.message, "Error")
      } else {
        this.toastr.error(error.error, "Error")
      }
      return of(result as T);
    };
  }
}
