import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../../../types/User";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../errorMessageService/error-message.service";
import {map} from "rxjs/operators";
import {CreateKeyDTO} from "../../../types/CreateKeyDTO";
import {OracleOrderNumberDTO} from "../../../types/OracleOrderNumberDTO";
import {IDDTO} from "../../../types/IDDTO";
import {UpdateLicenseDTO} from "../../../types/UpdateLicenseDTO";
import {StringDTO} from "../../../types/StringDTO";

@Injectable({
  providedIn: 'root'
})
export class AdminLicenseService{
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  //for local
  // private urlBase : string = "http://localhost:8050/admin/license";
  //for qa
  private urlBase = 'http://sparkfamily.org:8050/admin/license';
  //for production
  // private urlBase : string = "http://localhost:8050/admin/license";
  private createLicenseUrl : string  = this.urlBase + "/create";
  private formRolesUrl : string = this.urlBase + "/formRoles";
  private getKeyUrl : string = this.urlBase + "/getKey";
  private getAccountLicenseInfoUrl : string = this.urlBase + "/getAccountLicenseInfo";
  private revokeLicenseUrl : string = this.urlBase + "/revokeLicense";
  private revokeLicenseByKeyUrl : string = this.urlBase + "/revokeLicenseByKey";
  private unRevokeLicenseUrl : string = this.urlBase + "/unRevokeLicense";
  private unRevokeLicenseByKeyUrl : string = this.urlBase + "/unRevokeLicenseByKey";
  private revokeBlcUrl : string = this.urlBase + "/revokeBlc";
  private unRevokeBlcUrl : string = this.urlBase + "/unRevokeBlc";

  private updateLicenseUrl : string = this.urlBase + "/updateLicense";

  constructor(private http: HttpClient, private router: Router, private errorService : ErrorMessageService ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  createLicenseCall(dto : CreateKeyDTO) {
    this.errorService.clear();
    return this.http.post<any>(this.createLicenseUrl, dto);
  }

  getFormRoles(){
    this.errorService.clear();
    return this.http.get<any>(this.formRolesUrl);
  }


  getKeyByOrderNumber(dto : OracleOrderNumberDTO){
    this.errorService.clear();
    return this.http.post<any>(this.getKeyUrl, dto);
  }

  getAccountLicenseInfo(dto : IDDTO){
    console.log(dto);
    return this.http.post<any>(this.getAccountLicenseInfoUrl, dto);
  }

  revokeLicense(dto : IDDTO){
    console.log("in revokeLicense");
    console.log(dto);
    return this.http.post<any>(this.revokeLicenseUrl, dto);
  }

  revokeLicenseByKey(dto : StringDTO){
    console.log(dto);
    return this.http.post<any>(this.revokeLicenseByKeyUrl, dto);
  }

  unRevokeLicenseByKey(dto : StringDTO){
    console.log(dto);
    return this.http.post<any>(this.unRevokeLicenseByKeyUrl, dto);
  }

  revokeBlc(dto : StringDTO){
    console.log(dto);
    return this.http.post<any>(this.revokeBlcUrl, dto);
  }

  unRevokeBlc(dto : StringDTO){
    console.log(dto);
    return this.http.post<any>(this.unRevokeBlcUrl, dto);
  }

  unRevokeLicense(dto: IDDTO){
    console.log("in unRevokeLicense");
    console.log(dto);
    return this.http.post<any>(this.unRevokeLicenseUrl, dto);
  }

  updateLicense(dto : UpdateLicenseDTO){
    console.log("in update license");
    return this.http.post<any>(this.updateLicenseUrl, dto);
  }

}
