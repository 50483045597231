import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UpdateUserDTO} from "../../../../types/UpdateUserDTO";
import {AdminAccountUsersDTO} from "../../../../types/AdminAccountUsersDTO";
import {AccountUserDTO} from "../../../../types/AccountUserDTO";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatDialog, MatDialogConfig, MatDialogRef, MatTreeNestedDataSource} from "@angular/material";
import {AdminUserService} from "../../../../_services/admin/adminUserService/admin-user.service";
import {RoleInfo} from "../../../../types/RoleInfo";
import {CreateNewUserComponent} from "../../../../user-site/create-new-user/create-new-user.component";
import {ActivatedRoute} from "@angular/router";
import {AdminCreateNewUserComponent} from "../admin-create-new-user/admin-create-new-user.component";
import {AdminDeleteUserComponent} from "../admin-delete-user/admin-delete-user.component";
import {IDDTO} from "../../../../types/IDDTO";
import {UserRoleInfo} from "../../../../types/UserRoleInfo";

export class EditorNode {
  name: String;
  id?: number;
  children?: Array<EditorNode>;
  userDTO?:AccountUserDTO;

  editable: boolean;
}


@Component({
  selector: 'app-admin-account-user-editor',
  templateUrl: './admin-account-user-editor.html',
  styleUrls: ['./admin-account-user-editor.component.css']
})
export class AdminAccountUserEditorComponent implements OnInit {

  _adminAccountUsers : AdminAccountUsersDTO;


  treeData : Array<EditorNode> = new Array<EditorNode>();

  accountId : number;

  roleEditorLoaded : boolean = false;

  editUser : AccountUserDTO;

  roleStats: RoleInfo[];

  toggleStats : boolean = false;

  toggleTree : boolean = false;

  displayedColumns: string[] = ['name', 'qty', 'qtyFree'];

  @Output() reloadEditor=new EventEmitter<boolean>();

  constructor(private adminUserService : AdminUserService, private dialog: MatDialog) { }

  ngOnInit() {
  }


  @Input()
  set adminAccountUser(adminAccountUsers : AdminAccountUsersDTO){
    this._adminAccountUsers = adminAccountUsers;

    if(this._adminAccountUsers) {
      this.processLoad();
    }
  }

  processLoad(){
    this.accountId= this._adminAccountUsers.id;

    this.getStats();
    this.setTreeData();
    //this.dataSource.data = this.treeData;
  }

  getStats(){
    this.adminUserService.getAccountRoleInfo(this.accountId).subscribe(data => {
      console.log(data);
      this.roleStats = data;
      this.roleStats.forEach(element =>{
        if(element.type == "ADMIN"){
          element.qtyFree = "unlim";
        }
      })
    });
    this.toggleStats = !this.toggleStats;
  }

  setTreeData(){
    console.log(this.roleStats);
    this.toggleTree = !this.toggleTree;
    Array.from(this._adminAccountUsers.schoolUserMap.keys()).sort(function(a, b) {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    }).forEach( key => {

        let node : EditorNode = new EditorNode();
        let children : Array<EditorNode> = new Array<EditorNode>();
        node.name = key;
        node.editable = false;
        console.log(this._adminAccountUsers.schoolUserMap.get(key));

        this._adminAccountUsers.schoolUserMap.get(key).forEach(userInfo => {
          console.log(userInfo);
          let childNode : EditorNode = new EditorNode();
          childNode.name = userInfo.lastName + ', ' + userInfo.firstName;
          childNode.userDTO = userInfo;
          childNode.editable = true;
          children.push(childNode);
        })
        node.children = children;
        this.treeData.push(node);
      }
    );

    console.log(this.treeData);
  }

  getNewTreeData(){
    this.adminUserService.findAccount(new IDDTO(this.accountId)).subscribe( data => {
      console.log(data);
      console.log(Object.keys(data.adminAccountUsers.schoolUserMap).map((key) =>{return data.adminAccountUsers.schoolUserMap[key]}));
      if(data.success == true) {
        let id : number;
        let map : Map<String, Array<AccountUserDTO>> = new Map<String, Array<AccountUserDTO>>();
        id = data.adminAccountUsers.id;

        Object.keys(data.adminAccountUsers.schoolUserMap).map((key) =>
          {
            map.set(key, this.createDTO(data.adminAccountUsers.schoolUserMap[key]));
            //return data.adminAccountUsers.schoolUserMap[key]
          }
        );

        console.log(map);
        this._adminAccountUsers = new AdminAccountUsersDTO(id, map);

        this.treeData = new Array<EditorNode>();
        Array.from(this._adminAccountUsers.schoolUserMap.keys()).sort(function(a, b) {
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          return 0;
        }).forEach( key => {

            let node : EditorNode = new EditorNode();
            let children : Array<EditorNode> = new Array<EditorNode>();
            node.name = key;
            node.editable = false;
            console.log(this._adminAccountUsers.schoolUserMap.get(key));
            this._adminAccountUsers.schoolUserMap.get(key).forEach(userInfo => {
              console.log(userInfo);
              let childNode : EditorNode = new EditorNode();
              childNode.name = userInfo.lastName + ', ' + userInfo.firstName;
              childNode.userDTO = userInfo;
              childNode.editable = true;
              children.push(childNode);
            })
            node.children = children;
            this.treeData.push(node);
          }
        );

        console.log(this.treeData);


        this.toggleTree = !this.toggleTree;
      }
    });
  }

  createDTO(data){
    console.log(data);
    let dtos : Array<AccountUserDTO>= new Array<AccountUserDTO>();
    data.forEach( user => {
      let roleInfoArray : Array<UserRoleInfo> = new Array<UserRoleInfo>();
      let accountUserDTO : AccountUserDTO;
      user.roleInfo.forEach(roleInfo => {
        roleInfoArray.push(new UserRoleInfo(roleInfo.roleName, roleInfo.roleType, roleInfo.roleId, roleInfo.hasRole));
      });
      accountUserDTO = new AccountUserDTO(user.firstName, user.lastName, user.school, user.userId, roleInfoArray);
      dtos.push(accountUserDTO);
    });

    return dtos;
  }


  loadAddNewUser(){
    let createNewUserDialogConfig = new MatDialogConfig();
    createNewUserDialogConfig.data={id: this.accountId};
    createNewUserDialogConfig.autoFocus = true;
    createNewUserDialogConfig.disableClose = true;

    let dialogRef : MatDialogRef<AdminCreateNewUserComponent>  = this.dialog.open(AdminCreateNewUserComponent, createNewUserDialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if(data){
        this.reloadEditor.emit(true);
      }
    });

    console.log("open create new user");
  }

  reloadEditorCall(event){
    console.log("reloadEditor");
    console.log(event);
    if(event) {
      this.reloadEditor.emit(true);
    }
  }

  reloadStats(value){
    if(value) {
      this.getStats();
    }
  }

  reloadTree(value){
    console.log(value);
    if(value){
      this.getNewTreeData();
    }
  }

  loadEditorEvent(userDTO){
    console.log(userDTO);
    this.roleEditorLoaded = true;
    this.editUser = userDTO;
  }
}
