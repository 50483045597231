export class CreateKeyDTO {

  email:string;
  referenceNumber : string;
  duration : number;
  durationUnit : string;
  types:  Array<string>;
  qty = {};


  constructor(email : string, referenceNumber : string, duration : string, types: Array<string>, qty: Map<string, string>){
    this.email = email;
    this.referenceNumber = referenceNumber;
    let durationArray = duration.split(" ");
    this.duration = parseInt(durationArray[0]);
    this.durationUnit = durationArray[1];
    this.types = types;
    this.qty = qty;

    qty.forEach((val: string, key: string) => {
      this.qty[key] = val;
    });
  }
}
