import {Injectable} from '@angular/core';
import {UserFilterDTO} from "../../types/UserFilterDTO";
import {UserDTO} from "../../types/UserDTO";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../../_services/errorMessageService/error-message.service";
import {ToastrService} from "ngx-toastr";
import {PageEvent} from "@angular/material/paginator";
import {CourseDTO} from "../../types/CourseDTO";
import {CourseFilterDTO} from "../../types/CourseFilterDTO";
import {CourseEnrollmentFilterDTO} from "../../types/CourseEnrollmentFilterDTO";
import {CourseEnrollmentDTO} from "../../types/CourseEnrollmentDTO";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserCourseService {

  // private urlBase: string = "http://localhost:8050/se/user/";
  private urlBase: string = "http://sparkfamily.org:8050/se/user/";

  private filterCourseUrl: string = this.urlBase + "course/findAll";
  private courseSummaryUrl: string = this.urlBase + "course/course-summary?courseUrl=@courseUrl";
  private enrollInACourseUrl: string = this.urlBase + "course/enroll?courseId=@courseId";

  private getCourseByUrlUrl: string = this.urlBase + "course/getByUrl";
  private getMyCourses: string = this.urlBase + "course/myCourses";

  private findCourseLessonsSummaryByCourseUrlUrl: string = this.urlBase + "course/@id/lessons/findSummaryByCourseUrl";
  private getNextAndPreviousLessonUrl: string = this.urlBase + "course/nextAndPreviousLesson";
  private finishCurrentLessonUrl: string = this.urlBase + "course/finishLesson";
  private submitQuizAnswersUrl: string = this.urlBase + "course/submitQuizAnswers";

  private creditTransactionHistoryUrl: string = this.urlBase + "credits";

  private findCourseLessonContentsByCourseUrlUrl: string = this.urlBase + "course/@id/lessonContent";
  private findCourseLessonQuizsByCourseUrlUrl: string = this.urlBase + "course/@id/lessonQuiz";

  private downloadCertificateByCourseUrl: string = this.urlBase + "course/@url/certificate"


  constructor(private http: HttpClient, private router: Router, private errorService: ErrorMessageService, private toastr: ToastrService) {
  }

  filter(dto: CourseFilterDTO): Observable<any> {
    return this.http.post<any>(this.filterCourseUrl, dto).pipe(catchError(this.handleError('UserCourseService: FilterRecords', [])));
  }

  getSummary(courseUrl: string): Observable<any> {
    return this.http.get<any>(this.courseSummaryUrl.replace('@courseUrl', courseUrl)).pipe(catchError(this.handleError('UserCourseService: getSummary', [])));
  }

  enrollInACourse(courseId: number): Observable<any> {
    return this.http.post<any>(this.enrollInACourseUrl.replace('@courseId', courseId.toString()), null).pipe(catchError(this.handleError('UserCourseService: getSummary', [])));
  }

  getByCourseUrl(url: string) {
    return this.http.post<any>(this.getCourseByUrlUrl, url).pipe(catchError(this.handleError('ManageCourseService: getByCourseUrl', [])));
  }

  getEnrolledCourses() {
    return this.http.get<any>(this.getMyCourses + "?status=enrolled").pipe(catchError(this.handleError('ManageCourseService: getEnrolledCourses', [])));
  }

  getCompletedCourses() {
    return this.http.get<any>(this.getMyCourses + "?status=completed").pipe(catchError(this.handleError('ManageCourseService: getFinishedCourses', [])));
  }

  submitQuizAnswers(answers: any) {
    return this.http.post<any>(this.submitQuizAnswersUrl, answers).pipe(catchError(this.handleError('ManageCourseService: getFinishedCourses', [])));
  }

  findAllByCourseUrl(courseUrl: string): Observable<any> {
    return this.http.post<any>(this.findCourseLessonsSummaryByCourseUrlUrl.replace("@id", courseUrl), null).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  getNextAndPreviousLesson(lessonId: string): Observable<any> {
    let url = this.getNextAndPreviousLessonUrl + "/" + lessonId;
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageCourseContentService: getNextAndPreviousLesson', [])));
  }


  finishCurrentLesson(lessonId: string): Observable<any> {
    let url = this.finishCurrentLessonUrl + "/" + lessonId;
    return this.http.get<any>(url).pipe(catchError(this.handleError('ManageCourseContentService: getNextAndPreviousLesson', [])));
  }

  getCreditTransactionHistory(): Observable<any> {
    return this.http.get<any>(this.creditTransactionHistoryUrl).pipe(catchError(this.handleError('ManageUserService: getCreditTransactionHistory', [])));
  }

  findLessonContentsByCourseUrl(courseUrl: string, lessonId: string): Observable<any> {
    return this.http.get<any>(this.findCourseLessonContentsByCourseUrlUrl.replace("@id", courseUrl) + "/" + lessonId).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  findQuizContentsByCourseUrl(courseUrl: string, lessonId: string): Observable<any> {
    return this.http.get<any>(this.findCourseLessonQuizsByCourseUrlUrl.replace("@id", courseUrl) + "/" + lessonId).pipe(catchError(this.handleError('ManageCourseContentService: FindAll', [])));
  }

  downloadCertificate(courseUrl, courseTitle): Observable<any> {

    return this.http.get(this.downloadCertificateByCourseUrl.replace("@url", courseUrl), {responseType: 'blob'}).pipe(map((response) => {
      return {
        filename: courseTitle + '.pdf',
        data: response
      };
    }));
  }

  clear() {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("course service catch error");
      console.log("error1");
      console.error(error);
      console.log("error1");
      this.errorService.add("Error generating content");
      this.toastr.error(error.error, "Error")
      return of(result as T);
    };
  }
}
