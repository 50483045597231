import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {
  EmailValidation,
  PasswordValidation,
  RepeatEmailValidator,
  RepeatPasswordValidator
} from "../../helpers/validators";
import {AccountService} from "../../_services/accountService/account.service";
import {DisplayMessageService} from "../../_services/displayMessageService/display-message.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  resetError : boolean = false;


  forgotPasswordForm = this.fb.group({
    email: ['', EmailValidation]
  }, {});


  constructor(private fb: FormBuilder, private accountService : AccountService, private messageService : DisplayMessageService) { }

  ngOnInit() {
    this.messageService.clear();
  }

  onSubmit(){
    console.log("on forgot password submit");
    this.accountService.resetPassword(this.forgotPasswordForm.get('email').value).subscribe( data => {
        console.log(data);
        this.messageService.add(data.message);
      }
    )
  }

}
