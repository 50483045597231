import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';
import {IntroComponent} from './user-site/intro/intro.component';
import {LoginComponent} from './user-site/login/login.component';
import {SignupComponent} from './user-site/signup/signup.component';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HomeComponent} from './user-site/home/home.component';
import {ErrorMessageComponent} from './user-site/error-message/error-message.component';
import {AuthGuard} from "./helpers/guards/auth.guard";
import {NavComponent} from './user-site/nav/nav.component';
import {JwtInterceptor} from "./helpers/interceptors/jwt.interceptor";
import {NavEntryResolver, NavResolver} from "./helpers/resolvers/nav.resolver";
import {ModuleComponent} from './user-site/module/module.component';
import {RestrictedcurriculumComponent} from './user-site/restrictedcurriculum/restrictedcurriculum.component';
import {FileViewComponent} from './user-site/file-view/file-view.component';
import {ModuleListComponent} from './user-site/module-list/module-list.component';
import {ModuleListResolver} from "./helpers/resolvers/modulelist.resolver";
import {ModuleFileListComponent} from './user-site/module-file-list/module-file-list.component';
import {ModuleFileListResolver} from "./helpers/resolvers/moduleFileList.resolver";
import {UpdateProfileComponent} from './user-site/update-profile/update-profile.component';
import {AccountMenuComponent} from './user-site/account-menu/account-menu.component';
import {LicenseInfoComponent} from './user-site/license-info/license-info.component';
import {UserManagementComponent} from './user-site/user-management/user-management.component';
import {EarlierLicenseExpirationDateResolver, LicenseResolver} from "./helpers/resolvers/license.resolver";
import {AccountUserResolver} from "./helpers/resolvers/accountuser.resolver";
import {UserEditorComponent} from './user-site/user-editor/user-editor.component';
import {RoleCountsResolver} from "./helpers/resolvers/roleCounts.resolver";
import {DisplayMessageComponent} from './user-site/display-message/display-message.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatCardModule, MatCheckboxModule,
  MatDialogModule, MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule
} from "@angular/material";
import {DeleteUserDialogComponent} from './user-site/delete-user-dialog/delete-user-dialog.component';
import {CreateNewUserComponent} from './user-site/create-new-user/create-new-user.component';
import {ResetPasswordComponent} from './user-site/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './user-site/forgot-password/forgot-password.component';
import {AdminRestrictedComponent} from './user-site/admin-restricted/admin-restricted.component';
import {ModuleFolderContentsComponent} from './user-site/module-folder-contents/module-folder-contents.component';
import {
  ModuleFolderContentsResolver,
  TTModuleFolderContentsResolver
} from "./helpers/resolvers/moduleFolderContents.resolver";
import {CurrentFolderParentResolver, CurrentFolderResolver} from "./helpers/resolvers/currentFolder.resolver";
import {CurrentCurriculumResolver} from "./helpers/resolvers/currentCurriculum.resolver";
import {FileAudioComponent} from './user-site/file-audio/file-audio.component';
import {
  ModuleBreadcrumbResolver,
  ModuleFolderBreadcrumbResolver,
  TTModuleBreadcrumbResolver, TTModuleFolderBreadcrumbResolver
} from "./helpers/resolvers/breadcrumb.resolver";
import {
  CurrentFolderSecondaryPageResolver,
  CurrentModuleResolver,
  CurrentTTModuleResolver
} from "./helpers/resolvers/currentModule.resolver";
import {LandingComponent} from './user-site/landing/landing.component';
import {SelfRegisterComponent} from './user-site/self-register/self-register.component';
import {ComingSoonComponent} from './user-site/coming-soon/coming-soon.component';
import {InfoCaptureComponent} from './user-site/info-capture/info-capture.component';
import {FooterComponent} from './user-site/footer/footer.component';
import {MatButtonModule} from '@angular/material/button';
import {AdminDashboardModule} from "./admin-dashboard/admin-dashboard.module";
import {FaqComponent} from './user-site/faq/faq.component';
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {UniversityModuleComponent} from './user-site/university-module/university-module.component';
import {UniversityLinksResolver} from "./helpers/resolvers/university-links.resolver";
import {
  FolderSecondaryModuleListResolver,
  SecondaryModuleFileListResolver,
  SecondaryModuleListResolver
} from "./helpers/resolvers/secondaryModuleResolver.resolver";
import {SecondaryModuleFileListComponent} from './user-site/secondary-module-file-list/secondary-module-file-list.component';
import {TtPermissionsResolver} from "./helpers/resolvers/ttPermissions.resolver";
import {TtModuleResolver} from "./helpers/resolvers/ttModule.resolver";
import {TtModuleFileListComponent} from './user-site/tt-module-file-list/tt-module-file-list.component';
import {DownloadableViewComponent} from './user-site/downloadable-view/downloadable-view.component';
import {TtModuleFolderContentsComponent} from './user-site/tt-module-folder-contents/tt-module-folder-contents.component';
import {DodeaComponent} from "./user-site/dodea/dodea.component";
import {VideoViewComponent} from "./user-site/video-view/video-view.component";
import {UserPasswordEditorComponent} from './user-site/user-editor/user-password-editor/user-password-editor.component';
import {UserSchoolEditorComponent} from './user-site/user-editor/user-school-editor/user-school-editor.component';
import {SecondaryModuleFolderContentsComponent} from './user-site/secondary-module-folder-contents/secondary-module-folder-contents.component';
import {FreeTrialComponent} from './user-site/free-trial/free-trial.component';
import {PrivacyPolicyComponent} from './user-site/privacy-policy/privacy-policy.component';
import {ShowEulaPopupResolver} from "./helpers/resolvers/showEulaPopup.resolver";
import {AcceptEulaDialogComponent} from './user-site/accept-eula-dialog/accept-eula-dialog.component';

import {eAdminDashboardComponent} from "./spark-ecademy/admin/dashboard/dashboard.component";


import {eDashboardComponent} from "./spark-ecademy/user/dashboard/dashboard.component";

import {eAdminNavComponent} from "./spark-ecademy/admin/nav/admin-nav.component";
import {eAdminLessonNavComponent} from "./spark-ecademy/admin/course/courseContents/lessonNav/admin-lesson-nav.component";

import {eAdminManageDocumentComponent} from "./spark-ecademy/admin/document/manage-document.component";
import {eAdminCreateDocumentComponent} from "./spark-ecademy/admin/document/create/create-document.component";
import {eAdminFilterDocumentComponent} from "./spark-ecademy/admin/document/filter/filter-document.component";
import {eAdminListDocumentComponent} from "./spark-ecademy/admin/document/list/list-document.component";

import {eAdminManageUserComponent} from "./spark-ecademy/admin/user/manage-user.component";
import {eAdminCreateUserComponent} from "./spark-ecademy/admin/user/create/create-user.component";
import {eAdminFilterUserComponent} from "./spark-ecademy/admin/user/filter/filter-user.component";
import {eAdminListUserComponent} from "./spark-ecademy/admin/user/list/list-user.component";
import {eAdminViewUserComponent} from "./spark-ecademy/admin/user/view/view-user.component";

import {MatSelectModule} from "@angular/material/select";
import {ToastrModule} from "ngx-toastr";
import {ModalModule} from 'ngx-bootstrap/modal';
import { MatChipsModule } from '@angular/material/chips';

import { MatTableModule } from '@angular/material/table';
import {MatPaginatorModule} from "@angular/material";
import {MatSort} from "@angular/material";
import {MatSortModule} from "@angular/material/sort";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";


import {eAdminViewCourseComponent} from "./spark-ecademy/admin/course/view/view-course.component";
import {eAdminFilterCourseComponent} from "./spark-ecademy/admin/course/filter/filter-course.component";
import {eAdminCreateCourseComponent} from "./spark-ecademy/admin/course/create/create-course.component";
import {eAdminManageCourseComponent} from "./spark-ecademy/admin/course/manage-course.component";
import {eAdminListCourseComponent} from "./spark-ecademy/admin/course/list/list-course.component";
import {eAdminCourseEnrollmentListComponent} from "./spark-ecademy/admin/course/enrollments/list/list-course-enrollments.component";

import {eAdminViewOrganizationComponent} from "./spark-ecademy/admin/organization/view/view-organization.component";
import {eAdminFilterOrganizationComponent} from "./spark-ecademy/admin/organization/filter/filter-organization.component";
import {eAdminCreateOrganizationComponent} from "./spark-ecademy/admin/organization/create/create-organization.component";
import {eAdminManageOrganizationComponent} from "./spark-ecademy/admin/organization/manage-organization.component";
import {eAdminListOrganizationComponent} from "./spark-ecademy/admin/organization/list/list-organization.component";
import {eAdminEditCourseEnrollmentsComponent} from "./spark-ecademy/admin/course/enrollments/edit/edit-course-enrollments.component";


import {eAdminDashboardOrganizationComponent} from "./spark-ecademy/admin/organization/dashboard/dashboard-organization.component";
import {eAdminListOrganizationCourseComponent} from "./spark-ecademy/admin/organization/course/list/list-organization-course.component";
import {eAdminCreateOrganizationCourseComponent} from "./spark-ecademy/admin/organization/course/create/create-organization-course.component";
import {eAdminOrganizationManageCreditsComponent} from "./spark-ecademy/admin/organization/credit/history/credit-history.component";
import {eAdminOrganizationDistributeCreditsComponent} from "./spark-ecademy/admin/organization/credit/distribute/distribute-credit.component";

import {eAdminListOrganizationMemberComponent} from "./spark-ecademy/admin/organization/manageMembers/list/list-organization-member.component";


import {MatRadioModule} from "@angular/material/radio";
import {eAdminCreateCourseContentComponent} from "./spark-ecademy/admin/course/courseContents/createLesson/create-course-contents.component";
import {eAdminCreateCourseQuizComponent} from "./spark-ecademy/admin/course/courseContents/createQuiz/create-course-quiz.component";
import {eAdminListCourseContentsComponent} from "./spark-ecademy/admin/course/courseContents/list/list-course-contents.component";
import {eAdminQuizNavComponent} from "./spark-ecademy/admin/course/courseContents/quizNav/admin-quiz-nav.component";


import {eCourseSummaryComponent} from "./spark-ecademy/user/course/summary/course-summary.component";
import {eCourseSignupComponent} from "./spark-ecademy/user/course/signup/course-signup.component";
import {eCourseDetailsComponent} from "./spark-ecademy/user/course/details/course-details.component";
import {eCourseCatalogComponent} from "./spark-ecademy/user/course/catalog/course-catalog.component";
import {eUserNavComponent} from "./spark-ecademy/user/nav/user-nav.component";
import {eUserListDocumentComponent} from "./spark-ecademy/user/document/list/list-document.component";
import {eUserManageDocumentComponent} from "./spark-ecademy/user/document/manage-document.component";
import {eUserFilterDocumentComponent} from "./spark-ecademy/user/document/filter/filter-document.component";
import {eAssignLicenseComponent} from "./spark-ecademy/user/license/license-catalog.component";
import {eAdminAssignLicenseComponent} from "./spark-ecademy/admin/license/list/admin-license-catalog.component";
import {eAdminCreateLicenseComponent} from "./spark-ecademy/admin/license/create/admin-create-license.component";
import {eAdminCourseCatalogComponent} from "./spark-ecademy/admin/course/catalog/course-catalog.component";
import {eAdminCreateOnSiteCourseComponent} from "./spark-ecademy/admin/onsiteCourse/create/admin-create-onSite-course.component";
import {eAdminViewOnSiteCourseComponent} from "./spark-ecademy/admin/onsiteCourse/view/admin-view-onSite-course.component";
import {eAssignOnSiteCourseComponent} from "./spark-ecademy/user/onSiteCourse/assign/assign-onSite-course.component";
import {eAdminViewOrganizationMemberComponent} from "./spark-ecademy/admin/organization/manageMembers/view/view-organization-member.component";
import {eAdminCreateOrganizationMemberComponent} from "./spark-ecademy/admin/organization/manageMembers/create/create-organization-member.component";
import {eAdminCourseCategoryComponent} from "./spark-ecademy/admin/course/category/list/course-catalog.component";
import {eAdminCreateCourseCategoryComponent} from "./spark-ecademy/admin/course/category/create/create-course-category.component";
import {eAdminCourseTagComponent} from "./spark-ecademy/admin/course/tag/list/course-tag.component";
import {eAdminCreateCourseTagComponent} from "./spark-ecademy/admin/course/tag/create/create-course-tag.component";
import {eAdminDashboardMessageComponent} from "./spark-ecademy/admin/dashboardMessage/dashboardMessage.component";







@NgModule({
  imports: [
    ToastrModule.forRoot(),
    ModalModule.forRoot(),

    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,

    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatButtonModule,
    AdminDashboardModule,
    MatExpansionModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule
  ],
  declarations: [
    AppComponent,
    IntroComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent,
    ErrorMessageComponent,
    NavComponent,
    ModuleComponent,
    RestrictedcurriculumComponent,
    FileViewComponent,
    ModuleListComponent,
    ModuleFileListComponent,
    UpdateProfileComponent,
    AccountMenuComponent,
    LicenseInfoComponent,
    UserManagementComponent,
    UserEditorComponent,
    DisplayMessageComponent,
    DeleteUserDialogComponent,
    AcceptEulaDialogComponent,
    CreateNewUserComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    AdminRestrictedComponent,
    ModuleFolderContentsComponent,
    FileAudioComponent,
    LandingComponent,
    SelfRegisterComponent,
    ComingSoonComponent,
    InfoCaptureComponent,
    FooterComponent,
    FaqComponent,
    UniversityModuleComponent,
    SecondaryModuleFileListComponent,
    TtModuleFileListComponent,
    DownloadableViewComponent,
    TtModuleFolderContentsComponent,
    FaqComponent,
    DodeaComponent,
    VideoViewComponent,
    UserPasswordEditorComponent,
    UserSchoolEditorComponent,
    SecondaryModuleFolderContentsComponent,
    FreeTrialComponent,
    PrivacyPolicyComponent,
    AcceptEulaDialogComponent,

    eAdminNavComponent,
    eAdminLessonNavComponent,
    eAdminQuizNavComponent,

    eAdminDashboardComponent,

    eDashboardComponent,

    eAdminCourseCatalogComponent,

    eAdminManageDocumentComponent,
    eAdminCreateDocumentComponent,
    eAdminFilterDocumentComponent,
    eAdminListDocumentComponent,

    eAdminManageUserComponent,
    eAdminCreateUserComponent,
    eAdminFilterUserComponent,
    eAdminListUserComponent,
    eAdminViewUserComponent,

    eAdminCreateOnSiteCourseComponent,
    eAdminViewOnSiteCourseComponent,

    eAdminManageCourseComponent,
    eAdminCreateCourseComponent,
    eAdminFilterCourseComponent,
    eAdminListCourseComponent,
    eAdminViewCourseComponent,
    eAdminCourseEnrollmentListComponent,
    eAdminEditCourseEnrollmentsComponent,


    eAdminManageOrganizationComponent,
    eAdminCreateOrganizationComponent,
    eAdminFilterOrganizationComponent,
    eAdminListOrganizationComponent,
    eAdminDashboardOrganizationComponent,
    eAdminViewOrganizationComponent,
    eAdminOrganizationDistributeCreditsComponent,
    eAdminOrganizationManageCreditsComponent,

    eAdminListOrganizationMemberComponent,
    eAdminViewOrganizationMemberComponent,
    eAdminCreateOrganizationMemberComponent,

    eAdminListOrganizationCourseComponent,
    eAdminCreateOrganizationCourseComponent,

    eAdminCreateCourseContentComponent,
    eAdminCreateCourseQuizComponent,
    eAdminListCourseContentsComponent,

    eAdminAssignLicenseComponent,
    eAdminCreateLicenseComponent,

    eAdminCourseCategoryComponent,
    eAdminCreateCourseCategoryComponent,

    eAdminCourseTagComponent,
    eAdminCreateCourseTagComponent,
    eAdminDashboardMessageComponent,

    eCourseCatalogComponent,
    eCourseSummaryComponent,
    eCourseSignupComponent,
    eCourseDetailsComponent,

    eUserNavComponent,
    eUserListDocumentComponent,
    eUserManageDocumentComponent,
    eUserFilterDocumentComponent,

    eAssignLicenseComponent,

    eAssignOnSiteCourseComponent



  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    NavResolver,
    NavEntryResolver,
    ModuleListResolver,
    ModuleFileListResolver,
    LicenseResolver,
    EarlierLicenseExpirationDateResolver,
    AccountUserResolver,
    RoleCountsResolver,
    ModuleFolderContentsResolver,
    CurrentFolderResolver,
    CurrentCurriculumResolver,
    ShowEulaPopupResolver,
    CurrentModuleResolver,
    ModuleBreadcrumbResolver,
    ModuleFolderBreadcrumbResolver,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatButtonModule,
    UniversityLinksResolver,
    SecondaryModuleListResolver,
    SecondaryModuleFileListResolver,
    FolderSecondaryModuleListResolver,
    CurrentFolderSecondaryPageResolver,
    CurrentFolderParentResolver,
    TtPermissionsResolver,
    TtModuleResolver,
    CurrentTTModuleResolver,
    TTModuleBreadcrumbResolver,
    TTModuleFolderContentsResolver,
    TTModuleFolderBreadcrumbResolver],
  bootstrap: [AppComponent],
  entryComponents: [DeleteUserDialogComponent, CreateNewUserComponent, AcceptEulaDialogComponent]
})
export class AppModule {
}
